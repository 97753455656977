import {Dropdown} from "bootstrap";

document.addEventListener("DOMContentLoaded", () => {
    schedule();
});

function changeLeftPosition() {
    let stickyLeftElements = document.getElementsByClassName('sticky-left'),
        left = 0;

    //if (window.innerWidth < 992) {
        left =  window.scrollX;
    //}
          
    for (let i = 0; i < stickyLeftElements.length; i++) {
        stickyLeftElements[i].style.transform = `translateX(${left}px)`;
    }
}

function initFilter() {
    const filterItem = document.querySelectorAll('.schedule-filter .dropdown-item');

    for (let i = 0; i < filterItem.length; i++) {
        filterItem[i].addEventListener('click', function(event) {

            let selectedDay = parseInt(this.dataset.day, 10);
            let selectedArea = parseInt(this.dataset.area, 10);
            let scheduleBlocks = document.querySelectorAll('.schedule-date-separator, .schedule-block');

            if (Number.isInteger(selectedDay) && [0,4,5,6].includes(selectedDay)) {

                for (let j = 0; j < scheduleBlocks.length; j++) {
                    if (parseInt(scheduleBlocks[j].dataset.day) === selectedDay) {
                        if (scheduleBlocks[j].classList.contains('schedule-date-separator')) {
                            scheduleBlocks[j].style.display = "flex";
                        } else {
                            scheduleBlocks[j].style.display = "block";
                        }
                    } else {
                        scheduleBlocks[j].style.display = "none";
                    }
                }

            } else if (Number.isInteger(selectedArea)) {

                for (let j = 0; j < scheduleBlocks.length; j++) {
                    if (parseInt(scheduleBlocks[j].dataset.area) === selectedArea) {
                        if (scheduleBlocks[j].classList.contains('schedule-date-separator')) {
                            scheduleBlocks[j].style.display = "flex";
                        } else {
                            scheduleBlocks[j].style.display = "block";
                        }
                    } else {
                        if (scheduleBlocks[j].classList.contains('schedule-date-separator')) {
                            scheduleBlocks[j].style.display = "flex";
                        } else {
                            scheduleBlocks[j].style.display = "none";
                        }
                    }
                }

            } else {
                if (this.dataset.reset && this.dataset.reset === "true") {
                    for (let j = 0; j < scheduleBlocks.length; j++) {
                        if (scheduleBlocks[j].classList.contains('schedule-date-separator')) {
                            scheduleBlocks[j].style.display = "flex";
                        } else {
                            scheduleBlocks[j].style.display = "block";
                        }
                    }
                }
            }

            event.stopPropagation();
            event.preventDefault();

            let scheduleFilter = document.getElementById('js-schedule-filter');
            Dropdown.getOrCreateInstance(scheduleFilter).hide();

            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

            return false;
        });
    }
}

function schedule() {

    const schedule = document.getElementsByClassName('schedule')[0];
    const scheduleColumn = document.getElementsByClassName('schedule-column')[0];

    if (schedule) {
        
        document.addEventListener("scroll", () => { changeLeftPosition(); });
        document.addEventListener("resize", () => { changeLeftPosition(); });

        initFilter();
    }

    if (scheduleColumn) {
       initFilter();
    }

}
