import Splide from '@splidejs/splide';

document.addEventListener("DOMContentLoaded", () => {
   splide();
});

function splide() {
   var options = {
      perPage: 4,
      breakpoints: {
         576: {
            perPage: 1,
         },
         992: {
            perPage: 2,
         },
         1200: {
            perPage: 3,
         },
      },
      lazyLoad: 'nearby',
      pagination: false
   };


   if (document.querySelector('#splide-movies')) {
      new Splide('#splide-movies', {
         perPage: 6,
         breakpoints: {
            576: {
               perPage: 2,
            },
            992: {
               perPage: 3,
            },
            1200: {
               perPage: 4,
            },
         },
         lazyLoad: 'nearby',
         pagination: false
      }).mount();
   }
   if (document.querySelector('#splide-guests')) {
      new Splide('#splide-guests', options).mount();
   }
   if (document.querySelector('#splide-info')) {
      new Splide('#splide-info', {
      perPage: 3,
      breakpoints: {
         790: {
            perPage: 1,
         },
         1246: {
            perPage: 2,
         },
      },
      lazyLoad: 'nearby',
      pagination: false
   }).mount();
   }
   // window.console.log("splide ready");
}
/*
      sm: 576px,
      md: 768px,
      lg: 992px,
      xl: 1200px,
      xxl: 1400px,
      xxxl: 1500px,

 */