document.addEventListener("DOMContentLoaded", () => {
   navbar_class();
});
document.addEventListener("scroll", () => {
   navbar_class();
});

function navbar_class() {
   const
      top = window.scrollY,
      navbar = document.querySelector(".page-home .navbar-main"),
      toggleBtn = document.getElementById("btn-navbar-toggler"),
      b = 100;

   if (navbar && toggleBtn) {
      if (top > b || toggleBtn.ariaExpanded === "true") {
         navbar.classList.add("js-navbar-scrolled");
      } else {
         navbar.classList.remove("js-navbar-scrolled");
      }
   }
}

// toggle navbar
var navbarToggler = document.getElementById('navbarToggler');
var btnNavbarToggler = document.getElementById('btn-navbar-toggler');
if (navbarToggler) {
   
   btnNavbarToggler.addEventListener('click', function () {
      let navbar = document.querySelector(".page-home .navbar-main");
      navbar.classList.add("js-navbar-scrolled");
   });

   navbarToggler.addEventListener('shown.bs.collapse', function () {
      let navbar = document.querySelector(".page-home .navbar-main");
      navbar.classList.add("js-navbar-scrolled");
   });

   navbarToggler.addEventListener('hidden.bs.collapse', function () {
      navbar_class();
   });

}